import * as React from 'react'
import useConsentCheck from '../../consent/hooks/useConsentCheck'
import NoConsentPlaceholder from '$src/consent/components/NoConsentPlaceholder'

const WIDGET_HEIGHT = 500

export default function GetJennyWidget() {
  const [ref, setRef] = React.useState<HTMLDivElement | undefined>(undefined)
  const consent = useConsentCheck({
    requiredPurposeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  })

  const containerId = React.useMemo(() => {
    return `getjenny-widget-${Math.floor(Math.random() * 10000)}`
  }, [])

  React.useEffect(
    () => {
      if (!ref) {
        return
      }

      const scriptEl = document.createElement('script')
      scriptEl.setAttribute('class', 'getjenny-widget-bundle')
      scriptEl.setAttribute(
        'src',
        'https://widget-telwin.getjenny.com/static/js/main.js'
      )
      scriptEl.setAttribute(
        'data-connector-id',
        '26b5ec56-6913-11ef-8ead-069b33d65119'
      )
      scriptEl.setAttribute('data-title', '')
      scriptEl.setAttribute('data-title-Alignment', 'center')
      scriptEl.setAttribute('data-customer-Logo-Url', '')
      scriptEl.setAttribute('data-accent-Color', '#000000')
      scriptEl.setAttribute('data-button-Label', 'A-lehdet')
      scriptEl.setAttribute('data-placeholder', ' ')
      scriptEl.setAttribute('data-show-Getjenny-Reference', 'false')
      scriptEl.setAttribute('data-bot-Reply-Delay', '1')
      scriptEl.setAttribute('data-welcome-Text', '')
      scriptEl.setAttribute('data-disable-Chat-On-Buttons', 'true')
      scriptEl.setAttribute('data-chat-Location-Horizontal', 'right')
      scriptEl.setAttribute('data-chat-Margin-Horizontal', '250px')
      scriptEl.setAttribute('data-chat-Location-Vertical', 'top')
      scriptEl.setAttribute('data-chat-Margin-Vertical', '90px')
      scriptEl.setAttribute('data-open-Chat-On-Load', 'true')
      scriptEl.setAttribute('data-theme-Color', '#000000')
      scriptEl.setAttribute('data-title-Text-Color', '#eeeeee')
      scriptEl.setAttribute('data-footer-Color', '#000000')
      scriptEl.setAttribute('data-target-Element', containerId)
      scriptEl.setAttribute('data-chat-Corners', '2')
      scriptEl.setAttribute('data-bubble-Corners', '2 2 2 2')
      scriptEl.setAttribute('data-use-Chat-Icon', 'true')
      scriptEl.setAttribute('data-chat-Icon-Shape', '15 15 15 15')
      scriptEl.setAttribute('data-chat-Icon-Position', 'bottom: 30px; right: 30px;')
      scriptEl.setAttribute('data-show-Widget-Borders', 'false')
      scriptEl.setAttribute('data-shadows', '0 3px 7px -3px rgba(10,10,10,.1)')
      scriptEl.setAttribute('data-background-Color', '#ffff')
      scriptEl.setAttribute('data-disclaimer-Text', '')
      scriptEl.setAttribute('data-disclaimer-Background-Color', '#6539a1')
      scriptEl.setAttribute('data-chat-Icon-Url', '')
      scriptEl.setAttribute('data-widget-Shape', '2 2 2 2')
      scriptEl.setAttribute('data-bot-Icon-Border-Radius', '10')
      scriptEl.setAttribute('data-customer-Logo-Width-Percentage', '25')
      scriptEl.setAttribute('data-customer-Logo-Side-Margins', '10')
      scriptEl.setAttribute('data-send-Icon-Url', '')
      scriptEl.setAttribute('data-enable-High-Contrast-Option', 'true')
      scriptEl.setAttribute('data-enable-Font-Size-Option', 'true')
      scriptEl.setAttribute('data-identifier', '')
      scriptEl.setAttribute('data-reclickable-Buttons', 'false')
      scriptEl.setAttribute('data-enable-Feedback', 'false')
      scriptEl.setAttribute('data-chat-Icon-Width', '50px')
      scriptEl.setAttribute('data-chat-Icon-Height', '50px')
      scriptEl.setAttribute('data-contact-Background-Color', '#000000')
      scriptEl.setAttribute('data-title-Font', 'arial')
      scriptEl.setAttribute('data-width', '100%')
      scriptEl.setAttribute('data-height', `${WIDGET_HEIGHT}px`)
      scriptEl.setAttribute('data-timeout', '200')
      scriptEl.setAttribute('data-fixed-Size', 'true')
      scriptEl.setAttribute('data-footer-Url', '')
      scriptEl.setAttribute('data-input-Font', 'Verdana')
      scriptEl.setAttribute('data-button-Font', 'Verdana')
      scriptEl.setAttribute('data-footer-Text', '')
      scriptEl.setAttribute('data-widget-Font', 'Verdana')
      scriptEl.setAttribute('data-bot-Icon-Url', '')
      scriptEl.setAttribute('data-chat-Location', 'right')
      scriptEl.setAttribute('data-header-Exists', 'false')
      scriptEl.setAttribute('data-like-On-Color', '#6539a1')
      scriptEl.setAttribute('data-auto-Open-Time', '')
      scriptEl.setAttribute('data-bot-Icon-Width', '40')
      scriptEl.setAttribute('data-like-Off-Color', '#000000')
      scriptEl.setAttribute('data-background-Size', 'cover')
      scriptEl.setAttribute('data-bot-Icon-Height', '40')
      scriptEl.setAttribute('data-input-Font-Size', '14')
      scriptEl.setAttribute('data-button-Font-Size', '14')
      scriptEl.setAttribute('data-widget-Font-Size', '16')
      scriptEl.setAttribute('data-background-Repeat', 'no-repeat')
      scriptEl.setAttribute('data-background-Position', 'center')
      scriptEl.setAttribute('data-background-Image-Url', '')
      scriptEl.setAttribute('data-background-Transparency', '1')

      ref.appendChild(scriptEl)
    },
    [ref, containerId]
  )

  if (consent.consentAccepted === false) {
    return <NoConsentPlaceholder />
  } else if (!consent.consentAccepted) {
    return null
  }

  return (
    <div
      id={containerId}
      ref={(ref) => setRef(ref)}
      style={{
        minHeight: WIDGET_HEIGHT
      }}
    />
  )
}
