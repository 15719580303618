import * as React from 'react'
import styled from 'styled-components'

import Button from '../../shared/components/Button'

export default function NoConsentPlaceholder() {
  return (
    <S.Block className="is-block">
      <S.Component>
        <S.Title className="is-heading-xxl">Sisältöä ei voida näyttää</S.Title>
        <p className="is-body-s">
          A-lehtien palveluissa näytetään sosiaalisissa medioissa (mm. YouTube,
          Facebook, Instagram ja Twitter) julkaistuja sisältöjä. Valitsemasi
          evästeasetukset estävät tämän sisällön näyttämisen. Nähdäksesi sisällöt,
          sinun on hyväksyttävä sisällönjakelukumppaneidemme evästeet.
        </p>
        <S.Button size="large" type="primary" className="open-alehdet-cmp">
          Evästeasetukset
        </S.Button>
      </S.Component>
    </S.Block>
  )
}

const S = {
  Block: styled.div`
    padding: 0 var(--no-consent-placeholder--block-padding-horizontal);
    background-color: white;
  `,
  Component: styled.div`
    border: 1px solid var(--color-tone-light);
    padding: 20px 16px;
    text-align: center;
    border-radius: 8px;
  `,
  Title: styled.div`
    margin-bottom: 12px;
  `,
  Button: styled(Button)``
}
