import { useEffect, useRef, useState, useCallback } from 'react'

declare global {
  interface Window {
    __tcfapi: any
    gravitoCMP: any
  }
}

const log = console.log

export default function({
  requiredWhiteListVendors,
  requiredNonTcfVendors,
  requiredPurposeIds,
  requiredCustomPurposeIds
}: {
  requiredWhiteListVendors?: number[]
  requiredNonTcfVendors?: string[]
  requiredPurposeIds?: number[]
  requiredCustomPurposeIds?: number[]
} = {}): {
  consentAccepted: Boolean | undefined
  tcString?: string
} {
  const [tcfApi, setTcfApi] = useState(undefined)
  const [tcString, setTcString] = useState(undefined)
  const [consentAccepted, setConsentAccepted] = useState<Boolean>(undefined)

  // Memoize props to avoid accidental rerenders - changing props is not supported
  const optionsRef = useRef({
    requiredWhiteListVendors,
    requiredNonTcfVendors,
    requiredPurposeIds,
    requiredCustomPurposeIds
  })

  const checkConsent = useCallback(
    async () => {
      log('check consent')
      const {
        requiredWhiteListVendors,
        requiredNonTcfVendors,
        requiredPurposeIds,
        requiredCustomPurposeIds
      } = optionsRef.current
      const consentAccepted = await checkConsentAccepted(
        requiredWhiteListVendors,
        requiredNonTcfVendors,
        requiredPurposeIds,
        requiredCustomPurposeIds
      )
      log('accepted?', consentAccepted)
      setConsentAccepted(consentAccepted)
    },
    [optionsRef.current, tcString]
  )

  // Waits for tcf api and sets it to state when available
  useEffect(() => {
    waitForTcfApi()
    async function waitForTcfApi() {
      log('wait for tcf api')
      const tcfApi = (await getTcfApi()) as any
      log('got tcf api', !!tcfApi)
      setTcfApi(() => tcfApi)
    }
  }, [])

  // Listens to tcf api and sets tc string when available
  useEffect(
    () => {
      if (!window.__tcfapi) {
        return undefined
      }

      log('add tcf api listener')
      window.__tcfapi('addEventListener', 2, listener)
      return () => {
        window.__tcfapi('removeEventListener', 2, listener)
      }

      function listener(tcData, success) {
        log('got data?', success, tcData)
        if (success) {
          setTcString(tcData.tcString)
        }
      }
    },
    [tcfApi]
  )

  // Listens to tc string and checks consents when it changes
  useEffect(
    () => {
      if (!tcString) {
        return
      }
      log('checking consent')
      checkConsent()
    },
    [tcString]
  )

  return {
    consentAccepted,
    tcString
  }
}

async function checkConsentAccepted(
  requiredWhiteListVendors,
  requiredNonTcfVendors,
  requiredPurposeIds,
  requiredCustomPurposeIds
): Promise<any | undefined> {
  return new Promise((resolve) => {
    log('checking consent')
    // No consents required, so consent is accepted
    if (
      !requiredWhiteListVendors &&
      !requiredNonTcfVendors &&
      !requiredPurposeIds &&
      !requiredCustomPurposeIds
    ) {
      return resolve(true)
    }

    setTimeout(() => {
      if (!window.gravitoCMP || !window.gravitoCMP.currentState) {
        log('no gravito cmp')
        return resolve(undefined)
      }

      window.__tcfapi('getTCData', 2, (tcData) => {
        if (!tcData || !tcData.tcString) {
          log('no tc data')
          return resolve(undefined)
        }

        // Check if required whiteListVendors has consent
        if (requiredWhiteListVendors) {
          for (const requiredWhiteListVendor of requiredWhiteListVendors) {
            const whiteListedVendorIds = entries(tcData.vendor.consents)
              .filter(([, hasConsent]) => hasConsent)
              .map(([vendorId]) => Number(vendorId))

            const hasRequiredWhiteListVendorConsent = !!whiteListedVendorIds.find(
              (vendor) => vendor === requiredWhiteListVendor
            )

            if (!hasRequiredWhiteListVendorConsent) {
              log('fail vendor consent', tcData.vendor.consents)
              return resolve(false)
            }
          }
        }

        // Check Non-TCF vendors from Gravito CMP
        if (requiredNonTcfVendors) {
          for (const requiredNonTcfVendor of requiredNonTcfVendors) {
            const hasNonTcfVendorConsent = !!window.gravitoCMP.currentState.nonTCFVendors.find(
              (vendor) =>
                vendor.name === requiredNonTcfVendor && vendor.consent === true
            )
            if (!hasNonTcfVendorConsent) {
              log(
                'fail non tcf vendor consent',
                window.gravitoCMP.currentState.nonTCFVendors
              )
              return resolve(false)
            }
          }
        }

        // Check requires purposes
        if (requiredPurposeIds) {
          for (const requiredPurposeId of requiredPurposeIds) {
            const hasPurposesConsent =
              tcData.purpose.consents[requiredPurposeId] === true
            if (!hasPurposesConsent) {
              log('fail purposes consent', tcData.purpose.consents)
              return resolve(false)
            }
          }
        }

        // Check custom purposes
        if (requiredCustomPurposeIds) {
          for (const requiredCustomPurposeId of requiredCustomPurposeIds) {
            const hasCustomConsent =
              tcData.publisher.customPurpose.consents[requiredCustomPurposeId] ===
              true
            if (!hasCustomConsent) {
              log('fail custom consent', tcData.publisher.customPurpose.consents)
              return resolve(false)
            }
          }
        }

        log('accepted')
        return resolve(true)
      })
    }, 0)
  })
}

export async function getTcfApi() {
  return new Promise((resolve) => {
    log('polling tcf api')
    let counter = 0
    const interval = setInterval(() => {
      if (window.__tcfapi) {
        clearInterval(interval)
        resolve(window.__tcfapi)
        log('got tcf api')
      }
      counter++
      if (counter > 1000) {
        clearInterval(interval)
        resolve(undefined)
      }
    }, 100)
  })
}

function entries(obj) {
  const ownProps = Object.keys(obj)

  let i = ownProps.length
  const resArray = new Array(i)
  while (i--) {
    resArray[i] = [ownProps[i], obj[ownProps[i]]]
  }

  return resArray
}
